export const COLOR_VARIATION_SIZE = 48;
const S_L_VARIATIONS = [
  [10, 100, 25],
  [15, 90, 40],
  [20, 90, 60],
  [5, 90, 75],
];

export const createRandomColor = () => {
  return createPaletteColor(Math.floor(Math.random() * COLOR_VARIATION_SIZE));
};
export const createPaletteColor = (number: number) => {
  const hsl = S_L_VARIATIONS[Math.floor(number / 12)];
  const h = (number % 12) * 30 + hsl[0];
  const s = hsl[1];
  const l = hsl[2];
  const rgb = hsl2Rgb(h / 360, s / 100, l / 100);
  const rgbHex = rgb.map((v) => ("0" + v.toString(16)).slice(-2)).join("");
  return rgbHex;
};

export const color2rgbhsl = (colorStr: string) => {
  const color = parseInt(colorStr, 16);
  const r = (color >> 16) & 0xff;
  const g = (color >> 8) & 0xff;
  const b = color & 0xff;

  const [h, s, l] = rgb2hsl([r, g, b]);
  return [r, g, b, h, s, l];
};

/**
 * @param h hue(0 - 1)
 * @param s saturation(0 - 1)
 * @param l Luminance(0 - 1)
 */
function hsl2Rgb(h: number, s: number, l: number) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = function hue2rgb(p: number, q: number, t: number) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

/**
 * @param number[] [red(0 - 255), green(0 - 255), blue(0 - 255)]
 */
function rgb2hsl([r, g, b]: number[]) {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s ? (l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s) : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
}
