import { CSSProperties, css } from "styled-components";

export interface MarginProperties {
  $m: CSSProperties["margin"];
  $ml: CSSProperties["marginLeft"];
  $mt: CSSProperties["marginTop"];
  $mb: CSSProperties["marginBottom"];
  $mr: CSSProperties["marginRight"];
  $mx: CSSProperties["marginLeft"] | CSSProperties["marginRight"];
  $my: CSSProperties["marginTop"] | CSSProperties["marginBottom"];
}

export interface PaddingProperties {
  $p: CSSProperties["padding"];
  $pl: CSSProperties["paddingLeft"];
  $pt: CSSProperties["paddingTop"];
  $pb: CSSProperties["paddingBottom"];
  $pr: CSSProperties["paddingRight"];
  $px: CSSProperties["paddingLeft"] | CSSProperties["paddingRight"];
  $py: CSSProperties["paddingTop"] | CSSProperties["paddingBottom"];
}

export const margin = css<Partial<MarginProperties>>`
  ${({ $m, $ml, $mt, $mb, $mr, $mx, $my }) => {
    const css: CSSProperties = {};
    if ($m) {
      css["margin"] = isNaN(+$m) ? $m : $m + "px";
    }
    if ($mx) {
      css["marginLeft"] = isNaN(+$mx) ? $mx : $mx + "px";
      css["marginRight"] = isNaN(+$mx) ? $mx : $mx + "px";
    }
    if ($my) {
      css["marginTop"] = isNaN(+$my) ? $my : $my + "px";
      css["marginBottom"] = isNaN(+$my) ? $my : $my + "px";
    }
    if ($ml) {
      css["marginLeft"] = isNaN(+$ml) ? $ml : $ml + "px";
    }
    if ($mt) {
      css["marginTop"] = isNaN(+$mt) ? $mt : $mt + "px";
    }
    if ($mb) {
      css["marginBottom"] = isNaN(+$mb) ? $mb : $mb + "px";
    }
    if ($mr) {
      css["marginRight"] = isNaN(+$mr) ? $mr : $mr + "px";
    }
    return {
      margin: css["margin"],
      marginLeft: css["marginLeft"],
      marginTop: css["marginTop"],
      marginBottom: css["marginBottom"],
      marginRight: css["marginRight"],
    };
  }}
`;

export const padding = css<Partial<PaddingProperties>>`
  ${({ $p, $pl, $pt, $pb, $pr, $px, $py }) => {
    const css: CSSProperties = {};
    if ($p) {
      css["padding"] = isNaN(+$p) ? $p : $p + "px";
    }
    if ($px) {
      css["paddingLeft"] = isNaN(+$px) ? $px : $px + "px";
      css["paddingRight"] = isNaN(+$px) ? $px : $px + "px";
    }
    if ($py) {
      css["paddingTop"] = isNaN(+$py) ? $py : $py + "px";
      css["paddingBottom"] = isNaN(+$py) ? $py : $py + "px";
    }
    if ($pl) {
      css["paddingLeft"] = isNaN(+$pl) ? $pl : $pl + "px";
    }
    if ($pt) {
      css["paddingTop"] = isNaN(+$pt) ? $pt : $pt + "px";
    }
    if ($pb) {
      css["paddingBottom"] = isNaN(+$pb) ? $pb : $pb + "px";
    }
    if ($pr) {
      css["paddingRight"] = isNaN(+$pr) ? $pr : $pr + "px";
    }
    return {
      padding: css["padding"],
      paddingLeft: css["paddingLeft"],
      paddingTop: css["paddingTop"],
      paddingBottom: css["paddingBottom"],
      paddingRight: css["paddingRight"],
    };
  }}
`;

export type WidthProperty = {
  width: CSSProperties["width"];
  $w: number;
};
export type HeightProperty = {
  height: CSSProperties["height"];
  $h: number;
};

export const width = css<Partial<WidthProperty>>`
  ${({ width, $w }) => {
    if (typeof width === "number") {
      return `width: ${width}px;`;
    }
    if (typeof width === "string") {
      if (!Number.isNaN(width)) {
        return `width: ${width}px;`;
      }
      return `width: ${width};`;
    }
    if ($w) {
      return `width: ${$w}px;`;
    }
  }}
`;

export const height = css<Partial<HeightProperty>>`
  ${({ height, $h }) => {
    if (typeof height === "number") {
      return `height: ${height}px;`;
    }
    if (typeof height === "string") {
      if (!Number.isNaN(height)) {
        return `height: ${height}px;`;
      }
      return `height: ${height};`;
    }
    if ($h) {
      return `height: ${$h}px;`;
    }
  }}
`;
