import { css } from "styled-components";

export const fontBaseEnStr = `"Helvetica Neue", Helvetica, Arial, "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, Osaka, system-ui, sans-serif`;

export const fontBaseStr = `"Helvetica Neue", Helvetica, Arial, "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", system-ui, sans-serif`;

export const fontBase = css`
  font-family: ${fontBaseStr};
`;

export const latoBaseStr = `"Lato", "Roboto", "Helvetica Neue", Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif`;

// https://fonts.google.com/specimen/Lato
export const latoBase = css`
  font-family: ${latoBaseStr};
`;

// Dosis used in Logo of RIMO voice
// if you use Dosis add bellow to index.html
// <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@200;400;700&family=Lato&display=swap" rel="stylesheet">
export const dosisBaseStr = `Dosis, "ヒラギノ丸ゴシック Pro", "Hiragino Maru Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif`;

// export const fontBase = css`
//   font-family: -apple-system, "BlinkMacSystemFont", "Helvetica Neue", Helvetica, "Hiragino Sans",
//     "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic",
//     sans-serif;
// `;

export const systemFontStr = `"system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, serif`;
