import type { FC } from "react";

import styled from "styled-components";

export interface AvatarProps {
  src: string;
  alt: string;
  size?: number | undefined;
  square?: boolean | undefined;
}

export const Avatar: FC<AvatarProps> = ({ src, alt, size = 24, square = false }) => {
  return <Component src={src} alt={alt} width={size} height={size} data-square={square} />;
};

const Component = styled.img`
  border-radius: 50%;

  &[data-square="true"] {
    border-radius: 0;
  }
`;
