import React, { CSSProperties, PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { color2rgbhsl } from "./createTagColor";

interface CategoryItemProps {
  submitting?: boolean;
  color: string;
  className?: string;
}

export const Tag: React.FC<PropsWithChildren<CategoryItemProps>> = (props) => {
  const [r, g, b, h, s, l] = color2rgbhsl(props.color);
  const styleVars = {
    "--label-r": r,
    "--label-g": g,
    "--label-b": b,
    "--label-h": h,
    "--label-s": s,
    "--label-l": l,
  } as CSSProperties;
  return (
    <TagItem className={props.className} style={styleVars} $submitting={false}>
      {props.children}
    </TagItem>
  );
};

const TagItem = styled.div<{ $submitting?: boolean }>`
  margin-bottom: 8px;
  cursor: default;
  border: solid 1px;
  padding: 2px 8px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 1.6;
  transition: all 200ms;
  font-weight: 600;
  ${(p) =>
    p.$submitting &&
    css`
      opacity: 0.3;
    `}
  svg {
    width: 14px;
    height: 14px;
  }

  --lightness-threshold: 0.57;
  --background-alpha: 0.18;
  --lighten-by: calc(((var(--lightness-threshold) - var(--perceived-lightness)) * 100) * var(--lightness-switch));

  --perceived-lightness: calc(
    ((var(--label-r) * 0.2126) + (var(--label-g) * 0.7152) + (var(--label-b) * 0.0722)) / 255
  );
  ${(p) =>
    p.theme.mode === "dark"
      ? css`
          --lightness-switch: max(0, min(calc((var(--perceived-lightness) - var(--lightness-threshold)) * -1000), 1));
        `
      : css`
          --lightness-switch: max(0, min(calc((var(--perceived-lightness) - var(--lightness-threshold)) * 1000), 1));
        `}
  color: hsl(var(--label-h), calc(var(--label-s) * 1%), calc((var(--label-l) + var(--lighten-by)) * 1%));
  svg {
    color: hsl(var(--label-h), calc(var(--label-s) * 1%), calc((var(--label-l) + var(--lighten-by)) * 1%));
  }
  background: rgba(var(--label-r), var(--label-g), var(--label-b), var(--background-alpha));
  --border-alpha: 0.4;
  border-color: hsla(
    var(--label-h),
    calc(var(--label-s) * 1%),
    calc((var(--label-l) + var(--lighten-by)) * 1%),
    var(--border-alpha)
  );
`;
