export const matchesValidPath = (validPaths: string[], path: string) =>
  validPaths.some((validPath) => {
    const pathParts = path.split("/");
    const validPathParts = validPath.split("/");

    if (pathParts.length !== validPathParts.length && !validPathParts.includes("*")) return false;
    return validPathParts
      .map(
        (p, i) => p === pathParts[i] || p === "*" || (i === pathParts.length - 1 && pathParts[i].startsWith(p + "?"))
      )
      .every((v) => v);
  });
