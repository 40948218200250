"use client";

import { useCallback, useEffect, useState } from "react";
import i18next from "i18next";
import { initReactI18next, useTranslation as useTranslationOrg } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { getOptions, isLanguageCode, languageCodes } from "./settings";
import { UseTranslationOptions } from ".";
import { TFunction } from "i18next";
import { CustomTType } from "./types";

const runsOnServerSide = typeof window === "undefined";

i18next
  .use(initReactI18next)
  .use({
    type: "languageDetector",
    init: () => {},
    cacheUserLanguage: () => {},
    detect: () => {
      if (typeof window === "undefined") {
        return undefined;
      }

      const pathname = window.location.pathname.slice(1);
      const pathParts = pathname.split("/").filter(Boolean);
      const initialPath = pathParts[0];
      if (initialPath && isLanguageCode(initialPath)) {
        return initialPath;
      }
      return null;
    },
  })
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`../../apps/frontend/public/locales/${language}/${namespace}.json`)
    )
  )
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ["path", "cookie", "htmlTag", "navigator"],
    },
    preload: runsOnServerSide ? languageCodes : [],
    interpolation: {
      escapeValue: false, // HTML エスケープ処理を無効にする
    },
    nsSeparator: " >> ", // {namesapce} >> {key} のように使用することで、namespaceを指定できる
    keySeparator: false,
  });

type UseTranslationOrgType = {
  t: TFunction;
  i18n: {
    resolvedLanguage?: string;
    changeLanguage: (lng: string) => void;
    exists: (key: string) => boolean;
  };
};

type UseTranslationOrgReturnType = {
  customT: CustomTType;
  i18n: {
    resolvedLanguage?: string;
    changeLanguage: (lng: string) => void;
    exists: (key: string) => boolean;
  };
};

export function useTranslation(
  lng: string,
  ns: string,
  options: UseTranslationOptions = {}
): UseTranslationOrgReturnType {
  const { t, i18n }: UseTranslationOrgType = useTranslationOrg(ns, options);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const combinedT = useCallback((key: any, variables?: any) => {
    // 日本語が含まれている場合のみ変換する
    if (typeof key === "string" && /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF]/.test(key) && i18n.exists(key)) {
      return t(key, variables);
    } else {
      return key;
    }
  }, []);

  if (runsOnServerSide && lng && i18n.resolvedLanguage && i18n.resolvedLanguage !== lng) {
    i18n.changeLanguage(lng);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (activeLng === i18n.resolvedLanguage) return;
      setActiveLng(i18n.resolvedLanguage);
    }, [activeLng, i18n.resolvedLanguage]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!lng || i18n.resolvedLanguage === lng) return;
      i18n.changeLanguage(lng);
    }, [lng, i18n]);
  }

  return {
    customT: combinedT,
    i18n,
  };
}
