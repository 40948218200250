import { useClaims } from "@rimo/frontend/contexts/UserContext";
import { useVoiceInfo } from "@rimo/frontend/contexts/VoiceInfoContext";

export const useOrganizationId = () => {
  const { organizationId: claimOrgId } = useClaims();
  const { voiceInfo } = useVoiceInfo();
  const voiceOrgId = voiceInfo?.active_team_id;
  const loading = claimOrgId === undefined && voiceOrgId === undefined;

  if (loading) return undefined;

  const isFalsyClaimOrgId = !claimOrgId || claimOrgId === "";
  const isFalsyVoiceOrgId = !voiceOrgId || voiceOrgId === "";

  if (!isFalsyClaimOrgId) return claimOrgId;
  if (!isFalsyVoiceOrgId) return voiceOrgId;

  return null;
};
