import { formatISO, getDate } from "date-fns";

export type PlanType = "team" | "fixed" | "team202207";

export type TeamPlan = {
  plan_name: string;
  start_monthly_day: number;
  is_annual: boolean;
  discount_months?: number;
  start_at: string;
  end_at?: string | null;
  reserved_member_count: number;
  has_member_limit: boolean;
  base_reserved_duration_sec: number;
  has_duration_limit: boolean;
  exceedable_duration_sec: number;
  reserved_storage_volume: number;
  description?: string;
  is_free?: boolean;
  is_yearly_aggregation?: boolean;
  plan_type: PlanType;
  plan_code?: string;
  is_carry_over?: boolean;
  is_prepaid?: boolean;
  /** 利用時間を一括でまとめる月数 */
  aggregation_month?: number; // 12は年契約・それ以外は月契約
  used_duration_sec?: number; // used only fixed_plan
  auto_continue?: boolean; // used only 定額プラン
  customized_price?: number;
  disabled_supports?: string[];
  //
  id?: string;
  team_id?: string;
  organization_info_id?: string;
  used_storage_volume?: number;
  created_at?: Date;
  updated_at?: Date;
  year?: number;
  month?: number;
  day?: number;
  disabled_video?: boolean;
  disabled_dictionary?: boolean;
  security_option_enabled?: boolean;
  //
  all_reserved_duration_sec?: number; // response of plan list api
};

export type OrgInviteValidationResponse = {
  invitation_count: number;
  is_forbidden: boolean;
  is_warning: boolean;
  member_count: number;
  reserved_count: number;
};

export type ReservedDuration = {
  id?: string;
  organization_id: string;
  organization_info_id?: string;
  start_year: number;
  start_month: number;
  duration_sec: number;
  price: number;
  start_at?: string;
  canceled_at?: string;
  end_at?: string;
  is_cancelable_anytime: boolean;
  created_at?: string;
  updated_at?: string;
};

export type GetTeamPlanDateInfoResponse = {
  start_at: Date;
  end_at: Date;
  year: number;
  month: number;
};

export type SamlProviderInfo = {
  id: string;
  sp_entity_id: string;
  callback_url: string;
};

const now = new Date();

export const defaultPlans: TeamPlan[] = [
  {
    plan_name: "トライアル",
    reserved_member_count: 1000,
    base_reserved_duration_sec: 1500 * 3600,
    reserved_storage_volume: 100,
    plan_type: "team",

    start_monthly_day: getDate(now),
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: true,
  },
  {
    plan_name: "ライトプラン",
    reserved_member_count: 10,
    base_reserved_duration_sec: 15 * 3600,
    reserved_storage_volume: 100,
    plan_type: "team",

    start_monthly_day: getDate(now),
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: false,
  },
  {
    plan_name: "スタンダード",
    reserved_member_count: 31,
    base_reserved_duration_sec: 46.5 * 3600,
    reserved_storage_volume: 100,
    plan_type: "team",

    start_monthly_day: getDate(now),
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: false,
  },
  {
    plan_name: "プレミアム",
    reserved_member_count: 100,
    base_reserved_duration_sec: 150 * 3600,
    reserved_storage_volume: 100,
    plan_type: "team",

    start_monthly_day: getDate(now),
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: false,
  },
  {
    plan_name: "エキスパート",
    reserved_member_count: 1000,
    base_reserved_duration_sec: 1500 * 3600,
    reserved_storage_volume: 1100,
    plan_type: "team",

    start_monthly_day: getDate(now),
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: false,
  },
  {
    plan_name: "定額プラン",
    reserved_member_count: 1000,
    base_reserved_duration_sec: 20 * 3600, // 可変
    reserved_storage_volume: 1100,
    plan_type: "fixed",

    start_monthly_day: 1,
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: false,
    aggregation_month: 3,
  },
  {
    plan_name: "プリペイドプラン",
    reserved_member_count: 1000,
    base_reserved_duration_sec: 20 * 3600, // 可変
    reserved_storage_volume: 1100,
    plan_type: "fixed",
    is_prepaid: true,

    start_monthly_day: 1,
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: false,
    aggregation_month: 6,
  },
  {
    plan_name: "無料",
    reserved_member_count: 1000,
    base_reserved_duration_sec: 1500 * 3600,
    reserved_storage_volume: 100,
    plan_type: "team202207",
    start_monthly_day: getDate(now),
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: true,
  },
  {
    plan_name: "トライアル",
    reserved_member_count: 1000,
    base_reserved_duration_sec: 10 * 3600,
    reserved_storage_volume: 100,
    plan_type: "team202207",
    plan_code: "restricted_plan",
    start_monthly_day: getDate(now),
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: false,
    disabled_video: true,
    disabled_dictionary: true,
  },
  {
    plan_name: "ライト",
    reserved_member_count: 1000,
    base_reserved_duration_sec: 20 * 3600,
    reserved_storage_volume: 100,
    plan_type: "team202207",
    plan_code: "light_plan",

    start_monthly_day: getDate(now),
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: false,
  },
  {
    plan_name: "ベーシック",
    reserved_member_count: 1000,
    base_reserved_duration_sec: 45 * 3600,
    reserved_storage_volume: 100,
    plan_type: "team202207",
    plan_code: "basic_plan",

    start_monthly_day: getDate(now),
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: false,
  },
  {
    plan_name: "ビジネス",
    reserved_member_count: 1000,
    base_reserved_duration_sec: 100 * 3600,
    reserved_storage_volume: 100,
    plan_type: "team202207",
    plan_code: "business_plan",

    start_monthly_day: getDate(now),
    is_annual: false,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: false,
  },
  {
    // 半年40時間10万のプリペイドからの移行プラン => 年間80時間22万円 / 動画・辞書制限なし
    plan_name: "プリペイド移行版トライアル",
    reserved_member_count: 1000,
    base_reserved_duration_sec: 10 * 2400,
    reserved_storage_volume: 100,
    plan_type: "team202207",
    plan_code: "prepaid_succession_plan",
    start_monthly_day: getDate(now),
    is_annual: true,
    discount_months: 1,
    start_at: formatISO(now),
    has_member_limit: false,
    has_duration_limit: false,
    exceedable_duration_sec: 0,
    is_free: false,
    disabled_video: false,
    disabled_dictionary: false,
    aggregation_month: 12,
  },
];
