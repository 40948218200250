import React from "react";
import { MaterialUIThemeProvider } from "./MaterialUIThemeProvider";
import { Theme, ThemeModeProvider } from "./ThemeModeProvider";

export const CombinedThemeProvider: React.FC<React.PropsWithChildren<{ overrideMode?: Theme | null }>> = ({
  children,
  overrideMode,
}) => {
  return (
    <MaterialUIThemeProvider>
      <ThemeModeProvider overrideMode={overrideMode}>{children}</ThemeModeProvider>
    </MaterialUIThemeProvider>
  );
};
