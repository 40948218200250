import { getAuth, getIdTokenResult } from "firebase/auth";
import * as LaunchDarkly from "launchdarkly-js-client-sdk";

import { LDClientSideID, LDRelayUrl } from "../constants/services";

let ldClient: LaunchDarkly.LDClient;

function initialize() {
  const user: LaunchDarkly.LDUser = {
    anonymous: true,
  };
  // Configure Relay Proxy
  const options: LaunchDarkly.LDOptions = {
    streamUrl: LDRelayUrl,
    baseUrl: LDRelayUrl,
    eventsUrl: LDRelayUrl,
    fetchGoals: false,
    disableSyncEventPost: true,
    diagnosticOptOut: true,
    allAttributesPrivate: true,
    sendEvents: false,
  };
  const client = LaunchDarkly.initialize(LDClientSideID, user, options);
  return client;
}

export function getLDClient() {
  ldClient ??= initialize();
  return ldClient;
}

export async function identifyLDUser(ldUser?: LaunchDarkly.LDUser | undefined): Promise<LaunchDarkly.LDFlagSet | void> {
  try {
    const client = getLDClient();

    // 当該処理を入れると例外が発生する await client.waitForInitialization();

    // `ldUser` 引数が渡されなければFirebaseからユーザー情報を取得する
    const currentUser = ldUser ? null : getAuth().currentUser;
    const idTokenResult = !currentUser ? null : await getIdTokenResult(currentUser);

    const sessionUser: LaunchDarkly.LDUser = {
      key: currentUser?.uid,
      custom: {
        organization:
          typeof idTokenResult?.claims.organization_id === "string" ? idTokenResult.claims.organization_id : "",
      },
    };

    // LaunchDarklyに連携するデータがなければスキップする
    if (!ldUser && !idTokenResult) {
      console.error(
        "[LaunchDarkly] Firebaseからユーザー情報からFalsyな為 identify処理をスキップします",
        ldUser,
        idTokenResult
      );
      return;
    }

    return client.identify(ldUser ?? sessionUser, undefined, (err, flags) => {
      if (err) {
        console.error("[LaunchDarkly] onDone has error response", err);
      } else {
        console.info("[LaunchDarkly] identified, current user with flags", client.getUser(), flags);
      }
    });
  } catch (e) {
    console.error("[LaunchDarkly] Occurred identify error", e);
    throw e;
  }
}
