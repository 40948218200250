import styled, { css } from "styled-components";
import { MarginProperties, PaddingProperties, margin, padding } from "./system";

export interface CssPaperProps {
  /**
   * border-radius
   * @default 4
   */
  $r: number;
  $elevation?: never;
}

const cssPaper = css<Partial<CssPaperProps>>`
  background: ${({ theme }) => theme.vars.palette.background.surface};
  border-radius: ${({ $r = 4 }) => `${$r}px`};
`;

export const Paper = styled.div<Partial<CssPaperProps> & Partial<MarginProperties> & Partial<PaddingProperties>>`
  ${cssPaper}
  ${margin}
  ${padding}
`;
