import React from "react";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";

import { theme, ThemeType } from "./themePalette";

declare module "styled-components" {
  export interface DefaultTheme extends ThemeType {
    mode: "light" | "dark";
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    mode: "light" | "dark";
  }
}

declare module "@mui/material" {
  interface Palette {
    /**
     * @deprecated Use theme.mode instead of theme.palette.mode
     */
    mode: PaletteMode;
  }
}

export const MaterialUIThemeProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  return (
    <CssVarsProvider
      theme={theme}
      defaultMode="system"
      modeStorageKey="color-theme"
      colorSchemeStorageKey="color-scheme"
    >
      {props.children}
    </CssVarsProvider>
  );
};
