import { TeamRole } from "./organization";
import { User } from "../../types/user";

// model
export type OrganizationMember = {
  id: string;
  name: string;
  email: string;
  role: TeamRole;
  is_activated: boolean;
  /** @deprecated データが返ってきていない */
  team: string;
  profile: User | null;
};

// alias
export type OrgMember = OrganizationMember;

export type TeamCsvInput = {
  full_name: string;
  role: string; // 'admin" | "member"
};

// CSVで扱うチームメンバー型定義情報
export type TeamMemberCsvInput = {
  name: string;
  email: string;
  role: string;
  teams: TeamCsvInput[];
};

/**
 * メンバー名を取得する
 *
 * - (is_activated: true) はユーザー名を取得出来る
 * - (is_activated: false) はメールアドレスが取得出来る(data.profileはFalsy)
 */
export const getTeamMemberName = (data: OrganizationMember, defaultValue = ""): string => {
  if (!data.profile) {
    if (data.name) {
      return data.name;
    }
    return defaultValue;
  }

  const exactName = (data.profile.last_name + " " + data.profile.first_name).trim();

  if (exactName) return exactName;
  if (data.name) return data.name;
  if (data.profile.display_name) return data.profile.display_name;

  return defaultValue;
};

export const sortTeamMembersByAscii = (members: OrganizationMember[]) => {
  return [...members].sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  });
};

export const getActiveTeamMembers = (members: OrganizationMember[]) => {
  const arr = members.filter((v) => v.is_activated);
  return sortTeamMembersByAscii(arr);
};

export const getInvitedTeamMembers = (members: OrganizationMember[]) => {
  const arr = members.filter((v) => !v.is_activated);
  return sortTeamMembersByAscii(arr);
};

export const getTeamMembersByRole = (members: OrganizationMember[], role: TeamRole | string) => {
  if (role === TeamRole.SuperAdmin) {
    return members.filter((v) => v.role === TeamRole.SuperAdmin);
  }
  if (role === TeamRole.Admin) {
    return members.filter((v) => v.role === TeamRole.Admin);
  }
  if (role === TeamRole.Member) {
    return members.filter((v) => v.role === TeamRole.Member);
  }
  if (role === TeamRole.Invitation) {
    return members.filter((v) => v.role === TeamRole.Invitation);
  }
  return members;
};
