export const fallbackLng = "ja";
export const languageCodes = [fallbackLng, "en"] as const;
export const allLanguageCodes = [fallbackLng, "en", "zh"] as const;
export const defaultNS = "common";
export const cookieName = "i18next";
export const ns = [defaultNS, "special"];

// interview用のpromptをbackendに移したタイミングでenglishLabelを削除する
export interface Language {
  id: AllLanguageCodes;
  label: string;
  englishLabel: string;
}
export const languages: Language[] = [
  {
    id: "en",
    label: "英語",
    englishLabel: "English",
  },
  {
    id: "ja",
    label: "日本語",
    englishLabel: "Japanese",
  },
  // {
  //   id: "zh",
  //   label: "中国語(簡体)",
  // },
];

// [lng]による動的ルーティング対象パスリスト
// [lng]に不備がある場合リダイレクトする
// * := その下の階層すべて
export const appPaths = [
  "",
  "about/*",
  "blogs/*",
  "case-studies/*",
  "event/*",
  "policies/*",
  "form/*",
  "usecases/*",
  "voice/training",
  "login",
  "login/organizations",
];

export const japanOnlyPaths = ["policies/terms", "policies/privacy"];

export const japanExcludedPaths = ["about/ai-editor"];

type TupleToUnion<T extends readonly string[]> = T[number];
export type AllLanguageCodes = TupleToUnion<typeof allLanguageCodes>;

export type LangProps = {
  params: {
    lng: string;
  };
};

export const isLanguageCode = (code: string): code is AllLanguageCodes => {
  return allLanguageCodes.some((langCode: string) => langCode === code);
};

// 2文字の言語コードかどうか or ja-JP のような形式かどうか
export const isLocaleCodeFormat = (code: string) => {
  return /^[a-z]{2}(-[A-Z]{2})?$/.test(code);
};

export function getOptions(lng?: string) {
  return {
    // debug: true,
    supportedLngs: process.env.NEXT_PUBLIC_APP_ENV === "production" ? languageCodes : allLanguageCodes,
    fallbackLng,
    lng: lng || fallbackLng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
}
