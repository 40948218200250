import { Organization } from "../models";

export const isProd = process.env.NEXT_PUBLIC_APP_ENV === "production";
export const isStg = process.env.NEXT_PUBLIC_APP_ENV === "staging";
export const isDev = !isProd && !isStg;

export const isRimoLLC = (value: Organization | string | null | undefined): boolean => {
  const target = typeof value === "string" ? value : value?.id;
  switch (target) {
    case "Dt788f7DnXb6azxNJMcO":
      // production
      return true;
    case "MRUa5OBJ9IU5iIRDBsaW":
      // staging, development
      return true;
    default:
      return false;
  }
};
