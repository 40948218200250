import type { AnchorHTMLAttributes, ButtonHTMLAttributes, FC, PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "react";

import { css, styled } from "styled-components";

import { CssButtonProps, cssButton } from "./Button";
import {
  HeightProperty,
  MarginProperties,
  PaddingProperties,
  WidthProperty,
  height,
  margin,
  padding,
  width,
} from "./system";

/**
 * 共通コンポーネント Button をアイコンをPropsとして渡せるようにレイアウトを追加したコンポーネント
 */
interface ButtonInternalProperties {
  /**
   * normal: アイコンはラベルの相対位置とする
   * edge: アイコンはボタンの両端に配置する
   */
  $position: "normal" | "edge";
  $fullWidth: boolean;
  $textAlign: "left" | "center" | "right";
  startIcon: ReactNode;
  endIcon: ReactNode;
}

const defaultPosition = "normal";

type ButtonProps = Omit<CssButtonProps, "fullWidth"> &
  WidthProperty &
  HeightProperty &
  PaddingProperties &
  MarginProperties &
  ButtonInternalProperties;

export type DecorativeButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & PropsWithChildren<Partial<ButtonProps>>;

export type LinkButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  PropsWithChildren<
    Partial<
      ButtonProps & {
        href: string;
        external: boolean;
      }
    >
  >;

export const DecorativeButton = forwardRef<HTMLButtonElement, DecorativeButtonProps>(function DecorativeBtn(
  { children, startIcon, endIcon, $variant = "primary", $size = "normal", $r = 6, ...rest },
  ref
) {
  return (
    <Button
      ref={ref}
      $size={$size}
      $r={$r}
      $variant={$variant}
      $fullWidth={false}
      $startIcon={!!startIcon}
      $endIcon={!!endIcon}
      {...rest}
    >
      <Internal {...rest}>
        {startIcon}
        {children}
        {endIcon}
      </Internal>
    </Button>
  );
});

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(function LinkButton(
  {
    children,
    startIcon,
    endIcon,
    $position,
    $variant = "primary",
    $size = "normal",
    $r = 6,
    href,
    external = false,
    ...rest
  },
  ref
) {
  return (
    <Button
      ref={ref}
      $size={$size}
      $r={$r}
      $variant={$variant}
      $fullWidth={false}
      $position={$position}
      $startIcon={!!startIcon}
      $endIcon={!!endIcon}
      {...rest}
      as="a"
      href={href}
      target={external ? "_target" : rest.target}
      rel={external ? "noopener noreferrer" : rest.rel}
    >
      <Internal $position={$position}>
        {startIcon}
        {children}
        {endIcon}
      </Internal>
    </Button>
  );
});

const Button = styled.button<
  CssButtonProps &
    Partial<ButtonInternalProperties> &
    Partial<WidthProperty> &
    Partial<HeightProperty> &
    Partial<PaddingProperties> &
    Partial<MarginProperties> & {
      $startIcon: boolean;
      $endIcon: boolean;
    }
>`
  ${cssButton}
  margin: 0; // reset cssButton's
  ${width}
  ${height}
  ${padding}
  ${margin}
  ${({ $fullWidth }) => {
    if ($fullWidth) {
      return css`
        width: 100%;
      `;
    }
  }}
  ${({ $textAlign }) => {
    if ($textAlign) {
      return css`
        text-align: ${$textAlign};
      `;
    }
  }}
  ${({ $position = defaultPosition, $startIcon, $endIcon }) => {
    let columns;
    if ($startIcon && $endIcon) {
      columns = "auto 1fr auto";
    } else if ($startIcon && !$endIcon) {
      columns = "auto 1fr";
    } else if (!$startIcon && $endIcon) {
      columns = "1fr auto";
    } else {
      columns = "1fr";
    }
    if ($position === "edge") {
      return css`
        display: inline-grid;
        align-items: center;
        gap: 8px;
        grid-template-columns: ${columns};
      `;
    }
    if ($position === "normal") {
      return css`
        > .internal {
          display: inline-flex;
          align-items: center;
          gap: 8px;
        }
      `;
    }
  }}
`;

const Internal: FC<PropsWithChildren<Partial<{ $position: ButtonInternalProperties["$position"] }>>> = ({
  children,
  $position = defaultPosition,
}) => {
  if ($position === "normal") {
    return <p className="internal">{children}</p>;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
