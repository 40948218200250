import type { FC, ReactNode } from "react";
import styled from "styled-components";

interface AvatarGroupProps {
  children: ReactNode;
}

export const AvatarGroup: FC<AvatarGroupProps> = ({ children }) => {
  return <Component>{children}</Component>;
};

interface AvatarCountProps {
  count: number;
  operator?: string | undefined;
  variant?: "primary" | "secondary" | undefined;
  size: number;
}

export const AvatarCount: FC<AvatarCountProps> = ({ count, operator = "+", variant = "primary", size }) => {
  return (
    <NumWithOperator $variant={variant} $height={size} $width={size}>
      {operator}
      {count}
    </NumWithOperator>
  );
};

const Component = styled.div`
  display: flex;
  align-items: center;

  > :not(:first-child) {
    margin-left: -8px;
  }
`;

const NumWithOperator = styled.div<{ $variant: "primary" | "secondary"; $height: number; $width: number }>`
  height: ${({ $height }) => $height}px;
  min-width: ${({ $width }) => $width}px;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  padding-inline: 6px;

  ${({ theme, $variant }) => {
    return $variant === "primary"
      ? `background: ${theme.vars.palette.primary.main}; color: white;`
      : `background: ${theme.vars.palette.background.light}; color: ${theme.vars.palette.text.secondary};`;
  }}
`;
