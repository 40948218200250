import styled, { css } from "styled-components";
import { MOBILE_BREAKPOINT } from "./breakpoints";

export const Dropdown = styled.button<{ $isOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: ${(p) => p.theme.vars.palette.background.experimental.layeredLight};
  color: ${(p) => p.theme.vars.palette.text.secondary};
  border: solid 1px ${(p) => p.theme.vars.palette.divider};
  border-radius: 100px;
  padding: 4px 12px;
  outline: none;
  svg {
    transition: all 200ms;
    ${(p) => p.$isOpen && `transform: rotate(180deg);`}
    width: 20px;
    height: 20px;
    margin-left: 2px;
  }
`;

export const DropdownList = styled.ul`
  margin-top: 8px;
  position: absolute;
  padding: 8px 8px;
  background: ${(p) => p.theme.vars.palette.background.experimental.layeredLight};
  box-shadow:
    0px 4px 12px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.02);
  border-radius: 14px;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  overflow: scroll;
  max-width: 120px;
  max-height: 500px;
  z-index: 1;
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    max-width: 80px;
    max-height: 180px;
  }
`;

export const DropdownListItem = styled.li<{ $selected?: boolean }>`
  min-width: 100%;
  box-sizing: border-box;
  padding: 6px 8px;
  transition: all 200ms;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
  border-radius: 6px;
  letter-spacing: 0px;
  font-size: 13px;
  line-height: 16px;
  color: ${(p) => p.theme.vars.palette.text.primary};
  width: fit-content;
  ${(p) =>
    p.$selected &&
    css`
      background: ${p.theme.vars.palette.background.surface};
    `}
  &:hover {
    background: ${(p) => p.theme.vars.palette.background.surface};
  }
`;
