// model
import { Organization } from "./organization";
import { TeamPlan } from "./plan";

export type Team = {
  id: string;
  parent_id: string;
  root_id: string;
  category: "organization" | string;
  name: string;
  formal_name: string;
  full_name: string;
  description: string;
  payable: boolean;
  created_at: string;
  updated_at: string;
  member_ids: string[];
  role: string;
  children?: Team[]; // recursive
  plan_type?: string;
  plan_info?: TeamPlan;
  plan_monthly_summary?: PlanMonthlySummary;
  plan_amount_info?: AmountInfo;
  prev_plan_monthly_summary?: PlanMonthlySummary;
  admin_member_ids: string[];
  /** チームに設定可能な利用制限時間の最大値(ミリ秒) */
  duration_limit?: number | undefined;
  /** チームの現在の利用制限時間 */
  max_duration_limit?: number | undefined;
  /** チームが使用した利用時間 */
  used_duration?: number | undefined;
  is_private_channel?: boolean | undefined;
};

export const getChannelIdByTeam = (team: Team): string => `team${team.id}`;

export const isOrgTeam = (team: Team): boolean => team.category === "organization";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTeam = (target: any): target is Team => {
  return target && typeof target.category === "string" && target.category === "team";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isOrganization = (target: any): target is Organization => {
  if (!target && !(target as Team).category) return false;
  return target?.category === "organization";
};

export interface PlanMonthlySummary {
  id: string;
  team_id: string;
  organization_info_id: string;
  year: number;
  month: number;
  reserved_duration: number;
  used_duration: number;
  created_at: Date;
  updated_at: Date;
  start_at: Date;
  end_at: Date;
  reserved_duration_sec: number;
}

export interface AmountInfo {
  amount: number;
  monthly_amount: number;
  contract_amount: number;
  reserved_duration_sec: number;
  used_duration_sec: number;
  exceed_duration_sec: number;
  reserved_member_count: number;
  current_member_count: number;
  exceed_member_count: number;
  reserved_storage_volume: number;
  used_storage_volume: number;
  exceed_storage_volume: number;
  start_date: string;
  end_date: string;
  carry_overed_duration_sec?: number;
  activated: boolean;
}

export interface TeamPlanSummary {
  plan_info: TeamPlan;
  plan_monthly_summaries: PlanMonthlySummary[];
  amount_info: AmountInfo;
}
